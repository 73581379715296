import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useLocation, Link, BrowserRouter as Router } from "react-router-dom";
import algoLogo from "../../assets/logo-images/logo-color.png";

const Header = () => {
  const location = useLocation();

  window.onscroll = function () {
    scrollFunction();
  };
  function scrollFunction() {
    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      document.getElementById("header").classList.add("scroll-sticky");
    } else {
      document.getElementById("header").classList.remove("scroll-sticky");
    }
  }

  return (
    <>
      <Navbar className="top_navbar" sticky="top" id="header" expand="lg">
        <Container className="px-3">
          <Link
            to="/"
            className={`${location.pathname === "/" ? "active" : ""}`}
          >
            <img
              src={algoLogo}
              alt="algo logo"
              style={{ maxHeight: "50px", objectFit: "cover" }}
            />
          </Link>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            className="justify-content-end"
            id="basic-navbar-nav"
          >
            <Nav className="me-auto navbar_links">
              <Link
                to="/"
                className={`nav-link ${
                  location.pathname === "/" ? "active" : ""
                }`}
              >
                Home
              </Link>
              {/* <Link to="/" className="nav-link">Services</Link> */}
              {/* <Link to="/project" className="nav-link">Ventures</Link> */}
              <Link
                to="/service"
                className={`nav-link ${
                  location.pathname === "/service" ? "active" : ""
                }`}
              >
                {" "}
                Service
              </Link>
              <Link
                to="/about"
                className={`nav-link ${
                  location.pathname === "/about" ? "active" : ""
                }`}
              >
                About Us
              </Link>
              <Link
                to="/blog"
                className={`nav-link ${
                  location.pathname === "/blog" ? "active" : ""
                }`}
              >
                Blog
              </Link>
              <Link
                to="/contact"
                className={`nav-link ${
                  location.pathname === "/contact" ? "active" : ""
                }`}
              >
                Contact
              </Link>
              <Link
                to="/faqs"
                className={`nav-link ${
                  location.pathname === "/faqs" ? "active" : ""
                }`}
              >
                FAQs
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
  // }
};

export default Header;
